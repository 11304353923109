import React from 'react'
import getFluidImage from '../../util/getFluidImage'
import Heading from '../elements/Heading'
import styled from 'styled-components'
import Container from '../elements/Container'
import Button from '../elements/Button'
import Img from 'gatsby-image/withIEPolyfill'
import Spacer from '../elements/Spacer'
import SubHeading from '../elements/SubHeading'

import Flow from '../../images/flow.svg'
import LogoList from '../elements/LogoList'
import b from '../../styles/Breakpoints'

export default props => {
    const { title, image, button, subTitle, logos, logoTitle, toggleModal, alt_text: altText } = props

    const fluid = getFluidImage(image, {maxWidth: 768})

    return (
        <Hero>
            <Wrapper>
                <Inner>
                    <SubHeading>{subTitle}</SubHeading>
                    <Heading as="h1" lineHeight="1.3">{title}</Heading>
                    <Spacer size="50" />
                    <Button as="button" type="button" onClick={toggleModal}>{button}</Button>
                    <Spacer size="50" />
                    {logos.length ? <LogoList items={logos} title={logoTitle} /> : null}
                </Inner>
                <Image fluid={fluid} alt={ altText } />
            </Wrapper>
        </Hero>
    )
}

const Hero = styled.div`
    position: relative;
    z-index: 0;
    padding: var(--gutter--xl) 0 var(--gutter);
    height: auto;

    display: flex;
    flex-flow: column;

    @media ${b.large}{
        padding: var(--gutter) 0;
        min-height: 100vh;
    }
    
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;

        width: 100%;
        height: 100%;
        background: url(${Flow}) no-repeat top center / cover;
        opacity: 0.25;

        @media ${b.small}{
            opacity: 0.15;
        }
    }

    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 50%;
        z-index: -1;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    }
`

const Wrapper = styled(Container)`
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;

    @media ${b.content}{
        grid-template-columns: 1fr 1fr;
    }
`

const Inner = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    padding: 0;

    @media ${b.large}{
        align-items: flex-start;
        text-align: left;
    }

    @media ${b.content}{
        padding: 5rem 5rem 5rem 0;
    }
`

const ImageWrapper = styled.div`
    padding: 5rem;
    display: none;

    @media ${b.content}{
        display: block;
    }

    > div{
        position: relative;

        &:before{
            content: "";
            width: 100%;
            padding-top: 100%;
			 display: block;
        }
    }
`

const Image = ({ fluid, alt }) => (
    <ImageWrapper>
        <div>
            <Img alt={ alt } objectFit="contain" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} fluid={fluid} />
        </div>
    </ImageWrapper>
)