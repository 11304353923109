import styled from "styled-components"

const getLineHeight = ({ lineHeight }) => {
    return lineHeight ?? 1.5
}

export default styled.h2`
    line-height: ${getLineHeight};
    order: ${({order}) => order ?? 'inherit'};
    margin: ${({ margin }) => margin ?? 0};
`