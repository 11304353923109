import styled from 'styled-components'
import getMargin from '../../util/getMargin'

export default styled.span`
    background: var(--accent);
    font-size: var(--font);
    font-weight: 500;
    padding: 0.875em 1.5em;
    color: var(--background);
    margin: ${getMargin};

    line-height: inherit;
    border-radius: var(--b--radius);
    cursor: pointer;

    &:hover{
        background: var(--accent--tint);
    }

    &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
    }
`