import React from 'react'
import { Helmet } from "react-helmet"
import getFluidImage from '../util/getFluidImage'
import parse from 'html-react-parser';

export default props => {

    const { 
        meta_description: metaDescription,
        meta_title: metaTitle,
        social_image: socialImage
    } = props.data
    
    const parsed = parse(props.scripts)
    const scripts = parsed.length ? parsed : [parsed]
    
    const fluid = getFluidImage(socialImage, { maxWidth: 2880 })

    return (
        <Helmet>
            <title>{ metaTitle }</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" />            
            <meta name="description" content={ metaDescription } />
            <meta property="og:title" content={ metaTitle } />
            <meta property="og:description" content={ metaDescription } />
            <meta property="og:image" content={ fluid.src }/>
            <meta property="og:url" content="plus.grafikr.dk" />
            {
                scripts.map((x, i) => {
                    if ( typeof x !== 'object' ) return null
                    else {
                        const script = x.props
    
                        if ( script.dangerouslySetInnerHTML !== undefined ) {
                            return <script key={ i }>{ script.dangerouslySetInnerHTML.__html }</script>
                        } else if ( script.src !== undefined ) {
                            return <script key={ i } src={ script.src } />
                        } else return null
                    }
                })
            }
        </Helmet>
    )
}

