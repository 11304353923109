import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import styled from 'styled-components'
import Heading from '../elements/Heading'
import getMargin from '../../util/getMargin'

export default ({ blocks }) => {
    const serializers = {
        types: {
            block: props => {
                const style = props.node.style

                switch (style) {
                    case (style.length === 2 && style[0] === 'h' ? style : 'h2'):
                        return <Heading as={style}>{props.children}</Heading>
                    default:
                        return BlockContent.defaultSerializers.types.block(props)
                }
            }
        }
    }

    return <RichContent><BlockContent blocks={blocks} serializers={serializers} /></RichContent>
}

const getFontSize = ({ fontSize }) => fontSize ?? '1em'

export const RichContent = styled.div`
    font-size: ${getFontSize};
    line-height: 1.8;
    color: #B2B2B2;
    margin: ${getMargin};

    p{
        &:not(:last-child){
            margin-bottom: 1em;
        }
    }

    a{
        font-weight: 500;
        color: #fff;
        transition: 0.5s ease opacity;
        cursor: pointer;

        &:hover{
            opacity: 0.6;
        }
    }

    ${Heading}{
        margin-bottom: 1rem;
        
        &:not(:first-child){
            margin-top: 3rem;
        }
    }
`