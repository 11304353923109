import React from 'react'
import Hero from './Hero'
import Image from './Image'
import Testimonials from './Testimonials'
import TextImage from './TextImage'
import PageSlider from './PageSlider'
import Cases from './Cases'

export default props => {
    switch (props._type) {
        case 'hero':
            return <Hero {...props} />
        case 'image':
            return <Image {...props} />
        case 'textImage':
            return <TextImage {...props} />
        case 'testimonials':
            return <Testimonials {...props} />
        case 'pageSlider':
            return <PageSlider {...props} />
        case 'cases':
            return <Cases {...props} />
        default:
            return <h1>Code for section with type <strong>{props._type}</strong> isn't present 😭</h1>
    }
}