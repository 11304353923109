import styled from "styled-components"

export default styled.span`
    line-height: 1.2;
    order: ${({order}) => order ?? 'inherit'};
    margin: ${({ margin }) => margin ?? 0};

    display: block;
    font-size: var(--font--small);
    font-family: 'HK Compression';
    letter-spacing: 0.1em;
    margin-bottom: 2rem;
`