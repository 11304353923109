import React from 'react'
import b from '../styles/Breakpoints'
import styled from 'styled-components'
import Button from './elements/Button'
import ScrollLock from 'react-scrolllock'
import SectionHeader from './sections/SectionHeader'
import CloseIcon from '../images/close.svg'
import Pattern from '../images/pattern.svg'
import NewsletterForm from './elements/Form'


export default props => {
    const { modalOpen, toggleModal } = props
    
    return (
        <Modal className={modalOpen ? 'open' : ''}>
            <Inner>
                <Close as="button" onClick={toggleModal} />
                <ScrollableContent>
                    <SectionHeader align="center" hideButton {...props} />
                    <NewsletterForm {...props} />
                </ScrollableContent>
            </Inner>
            <Overlay type="button" onClick={toggleModal} />
            <ScrollLock isActive={modalOpen}/>
        </Modal>
    )
}

const Overlay = styled.button`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background: url(${Pattern}) repeat top left / 0.5rem;
    opacity: 0;

    transition: 0.5s ease opacity;
`

const ScrollableContent = styled.div`
    display: flex;
    flex-flow: column;
    overflow: auto;

    justify-content: center;
    text-align: center;
    padding: calc(10rem + var(--size) + var(--gap)) 5% 10rem;
    flex: 1 1 0%;

    @media ${b.medium}{
        padding: 10rem;
    }

    @media ${b.desktop}{
        padding: 12rem;
    }
`

const Inner = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;

    display: flex;
    flex-flow: column;
    justify-content: center;
    
    background: var(--dark);
    transform: translateX(100%) translateX(var(--size)) translateX(var(--gap));
    transition: 0.7s ease transform;

    @media ${b.medium}{
        width: 90%;
    }

    @media ${b.content}{
        width: 50%;
        max-width: 72rem;
    }
`

const Modal = styled.div`
    --size: 5.2rem;
    --gap: 2rem;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    flex-flow: column;
    pointer-events: none;

    &.open{
        pointer-events: auto;

        ${Overlay}{
            opacity: 1;
        }

        ${Inner}{
            transform: translateX(0);
        }
    }
`

const Close = styled(Button)`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    margin: var(--gap) auto;
    width: var(--size);
    height: var(--size);
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    &:before{
        content: "";

        font-size: 2.4rem;
        width: 1em;
        height: 1em;

        display: block;
        background: url(${CloseIcon}) no-repeat center / contain;

        transition: 0.5s ease transform;
    }

    @media ${b.medium}{
        left: auto;
        right: 100%;
        transform: translateX(0);
        margin: var(--gap);

        &:hover{
            &:before{
                transform: rotate(180deg);
            }
        }
    }
`