import React from 'react'
import styled from 'styled-components'
import b from '../../styles/Breakpoints'
import Testimonial from './Testimonial'

export default props => {
    const items = props?.allSanityTestimonial?.nodes

    return (
        <List>
        {
            items.map(i => {
                return <Testimonial key={i._id} {...i} />
            })
        }
        </List>
    )
}

const List = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calc(var(--spacer) * 1.1);

    &:not(:empty){
        margin-top: var(--spacer--xl);
    }

    @media ${b.medium}{
        grid-template-columns: repeat(3, 1fr);
        grid-gap: var(--spacer);
    }
`