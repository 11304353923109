import React from 'react'
import parse from 'html-react-parser';

export default props => {
    
    const parsed = parse(props.data)
    const scripts = parsed.length ? parsed : [parsed]

    return (
        scripts.map((x, i) => {
            if ( typeof x !== 'object' ) return null
            else {
                const script = x.props
    
                if ( script.dangerouslySetInnerHTML !== undefined ) {
                    return <script type="text/javascript" key={ i } dangerouslySetInnerHTML={{__html: script.dangerouslySetInnerHTML.__html}}></script>
                } else if ( script.src !== undefined ) {
                    return <script key={ i } src={ script.src } />
                } else return null
            }
        })
    )
    
}

