import React from 'react'
import styled from 'styled-components'
import b from '../../styles/Breakpoints'
import getFluidImage from '../../util/getFluidImage'
import Container from '../elements/Container'
import SectionHeader from './SectionHeader'
import Img from 'gatsby-image'
import Flickity from 'react-flickity-component'

export default props => {
    const { pages } = props

    const options = {
        freeScroll: true,
        contain: true
    }

    return (
        <PageSlider>
            <Container>
                <SectionHeader {...props} />
                <Slider options={options}>
                {
                    pages.map(image => (
                        <SliderItem key={image._key}>
                            <Img alt={ image.asset.originalFilename.split('.')[0] } fluid={ getFluidImage(image, { maxWidth: 780 }) } />
                        </SliderItem>
                    ))
                }
                </Slider>
            </Container>
        </PageSlider>
    )
}

const SliderItem = styled.div`
    width: ${100/1.1}%;
    margin-right: 1rem;
    border-radius: var(--b--sradius);
    overflow: hidden;

    @media ${b.small}{
        width: ${100/2}%;
    }

    @media ${b.large}{
        width: ${100/3}%;
    }
`

const Slider = styled(Flickity)`
    outline: none;
    margin-top: var(--spacer--xl);
`

const PageSlider = styled.div`
    overflow: hidden;
    margin: var(--gutter) auto;
`