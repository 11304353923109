import React from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'

import Container from '../elements/Container'
import TestimonialList from '../elements/TestimonialList'
import SectionHeader from './SectionHeader'

export default props => {
    return (
        <Testimonials>
            <Container>
                <SectionHeader {...props} />
                <StaticQuery query={query} render={data => <TestimonialList {...data} />} />
            </Container>
        </Testimonials>
    )
}

const query = graphql`
  query {
    allSanityTestimonial(limit: 3) {
      nodes {
        _id
        _rawDescription
        jobTitle
        title
      }
    }
  }
`

const Testimonials = styled.div`
    background: var(--dark);
    padding: var(--gutter) 0;
    margin: var(--gutter) auto;

    &:last-child{
        margin-bottom: 0;
    }
`