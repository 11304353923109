import React from 'react'
import Heading from '../elements/Heading'
import SubHeading from '../elements/SubHeading'
import BlockContent from '../helpers/BlockContent'
import Button from '../elements/Button'
import TextContainer from '../elements/TextContainer'
import Spacer from '../elements/Spacer'
import styled from 'styled-components'

export default props => {
    const { title, subTitle, button, description, toggleModal, hideButton } = props

    return (
        <Container {...props}>
            <SubHeading>{subTitle}</SubHeading>
            <Heading>{title}</Heading>
            { description?.length ? (
                <>
                    <Spacer size="30" />
                    <BlockContent blocks={description} />
                </>
            ) : null }
            { button && !hideButton ? <Button marginTop="3rem" as="button" onClick={toggleModal} type="button">{button}</Button> : null }
        </Container>
    )
}

const getAlignment = ({ align }) => {
    switch (align) {
        case 'center':
            return 'center'
        case 'right': 
            return 'flex-end'
        default:
            return 'flex-start'
    }
}

const getTextAlignment = ({ align }) => {
    return align ?? 'left'
}

const Container = styled(TextContainer)`
    align-items: ${getAlignment};
    text-align: ${getTextAlignment};
    margin: ${props => getTextAlignment(props) === 'center' ? `0 auto` : `0`};
`