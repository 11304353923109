import React from 'react'
import Section from './sections/Section'

export default (props) => {
    const { content, properties } = props

    return (
        <>
            {
                content.map(section => <Section key={section._key} {...section} {...properties} />)
            }
        </>
    )
}