import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import getFluidImage from '../../util/getFluidImage'
import Spacer from '../elements/Spacer'
import b from '../../styles/Breakpoints'

export default props => {
    const { items, title } = props

    return (
        <LogoList>
            <Title>{title}</Title>
            <Spacer size="20" />
            <Wrapper>
            {items.map(({ title, image, _key }) => {
                const fluid = getFluidImage(image, { maxWidth: 140 })

                return (
                    <Logo key={_key}>
                        <Img fluid={fluid} objectFit="contain" />
                    </Logo>
                )
            })}
            </Wrapper>
        </LogoList>
    )
}

const LogoList = styled.div`
    margin-top: auto;
    display: flex;
    flex-flow: column;
    width: 100%;
`

const Title = styled.span`
    font-size: var(--font--small);
    color: #808080;
`

const Logo = styled.div`
    display: flex;
    flex-flow: column;

    max-width: 8rem;
    max-height: 3rem;
    width: 100%;
    height: auto;
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;

    @media ${b.large}{
        grid-template-columns: repeat(4, 1fr);
        justify-items: start;
    }
`