import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PageBuilder from '../components/PageBuilder'
import Modal from '../components/Modal'
import Meta from '../components/Meta'
import BodyScripts from '../components/BodyScripts'

export default props => {
    
    const { 
        _rawContent, 
        _rawModal, 
        _rawSeo, 
        thirdPartyScriptsHead,
        thirdPartyScriptsBody
    } = props?.data?.sanityPage
    
    let [modalOpen, updateModal] = useState(false)

    const toggleModal = e => {
        e.preventDefault()
        updateModal(!modalOpen)
    }

    const properties = {
        content: _rawContent,
        modalOpen,
        toggleModal
    }
    
    return (
        <>
            <Meta data={ _rawSeo } scripts={ thirdPartyScriptsHead } />
            <Modal {...properties} {..._rawModal} />
            <PageBuilder properties={properties} {...properties} />
            <BodyScripts data={ thirdPartyScriptsBody } />
        </>
    )
}

export const query = graphql`
    query($id: String!) {
        sanityPage(id: {eq: $id}){
            title
            _rawContent(resolveReferences: {maxDepth: 10})
            _rawModal
            _rawSeo
            thirdPartyScriptsHead
            thirdPartyScriptsBody
        }
    }
`
