import React from 'react'
import styled from 'styled-components'
import BlockContent from '../helpers/BlockContent'

export default props => {
    const { title, _rawDescription, jobTitle } = props

    return (
        <Testimonial>
            <BlockContent blocks={_rawDescription} />
            <Inner>
                <Name>{title}</Name>
                <Job>{jobTitle}</Job>
            </Inner>
        </Testimonial>
    )
}

const Testimonial = styled.li`
    display: flex;
    flex-flow: column;
`

const Inner = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 2rem;
`

const Name = styled.p`
    font-weight: bold;
`

const Job = styled.p`
    font-size: var(--font--x-small);
    margin-top: 0.2em;
`