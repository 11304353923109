import React, { useRef } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import styled from 'styled-components'
import Button from './Button'
import { RichContent } from '../helpers/BlockContent'

export default props => {
    const { formUrl } = props

    return (
        <MailchimpSubscribe
            url={formUrl}
            render={(mailchimp) => <Form {...mailchimp} {...props} />}
        />
    )
}

const Form = props => {
    
    const { subscribe, status, message, button } = props

    const FNAME = useRef()
    const LNAME = useRef()
    const EMAIL = useRef()
    const COMPANY = useRef()

    const getValue = (ref) => {
        return ref?.current?.value ?? ''
    }

    const submit = e => {
        e.preventDefault()

        return subscribe({
            FNAME: getValue(FNAME),
            LNAME: getValue(LNAME),
            EMAIL: getValue(EMAIL),
            COMPANY: getValue(COMPANY)
        })
    }

    return (
        <FormElement onSubmit={submit}>
            <InputGroup>
                <Input type="text" ref={FNAME} autoComplete="given-name" placeholder="Fornavn" required />
                <Input type="text" ref={LNAME} autoComplete="family-name" placeholder="Efternavn" required />
            </InputGroup>

            <InputGroup>
                <Input type="text" ref={COMPANY} autoComplete="organization" placeholder="Virksomhed" required />
            </InputGroup>

            <InputGroup>
                <Input type="email" ref={EMAIL} autoComplete="email" placeholder="Email" required />
            </InputGroup>

            <Button 
                as="button"
                type="submit"
                name="submit"
                disabled={status === "sending"}
            >
                {button}
            </Button>

            {
                message ? (
                    <RichContent marginTop="2rem" fontSize="var(--font--small)">
                        <p dangerouslySetInnerHTML={{__html: message}} />
                    </RichContent>
                ) : null
            }
        </FormElement>
    )
}

const FormElement = styled.form`
    --formSpacing: 0.5rem;

    display: flex;
    flex-flow: column;
    margin-top: 5rem;

    ${Button}{
        margin-top: calc(var(--formSpacing) * 2);
    }
`

const InputGroup = styled.div`
    display: flex;
    margin: calc(var(--formSpacing) * -1);

    &:not(:first-child){
        margin-top: var(--formSpacing);
    }
`

const Input = styled.input`
    font-family: inherit;
    font-size: 1.4rem;
    line-height: inherit;

    padding: 1em;
    border-radius: var(--b--radius);
    border: none;
    outline: none;
    min-width: 0;

    flex: 1 1 0%;
    margin: var(--formSpacing);
`