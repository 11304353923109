import React from 'react'
import b from '../../styles/Breakpoints'
import Container from '../elements/Container'
import styled from 'styled-components'
import SectionHeader from './SectionHeader'

export default props => {

    const { image } = props

    return (
        <TextImage background={image?.asset?.url}>
            <Wrapper>
                <SectionHeader {...props} />
            </Wrapper>
        </TextImage>
    )
}

const Wrapper = styled(Container)`
    padding: 30% 0 var(--gutter);

    @media ${b.medium}{
        padding-top: var(--gutter) 0;
    }
`

const getBackground = ({ background }) => {
    return background
}

const TextImage = styled.div`
    --backgroundDim: 500% 0 / 90% 70%;

    background: url(${getBackground}) no-repeat var(--backgroundDim);

    @media ${b.medium}{
        --backgroundDim: 105% 50% / 40% 100%;
    }
`