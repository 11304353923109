import React from 'react'
import getFluidImage from '../../util/getFluidImage'
import Img from 'gatsby-image'

export default props => {

    const fluid = getFluidImage(props, { maxWidth: 2880 })
    
    return (
        <Img fluid={fluid} />
    )
}