import React from 'react'
import styled from 'styled-components'
import b from '../../styles/Breakpoints'
import SectionHeader from './SectionHeader'
import Container from '../elements/Container'
import Case from '../elements/Case'

export default props => {

    const { cases } = props

    return (
        <Cases>
            <Container>
                <SectionHeader align={'center'} {...props} />
                <CaseList>
                {
                cases.map(item => (
                    <Case key={item._key} {...item} />
                ))
                }
                </CaseList>
            </Container>
        </Cases>
    )
}

const Cases = styled.section`
    background: var(--dark);

    padding: var(--gutter) 0;
    margin: var(--gutter) auto;

    &:last-child{
        margin-bottom: 0;
        padding-bottom: var(--gutter--xl);
    }
`

const CaseList = styled.div`
    --columns: 1fr;

    display: grid;
    grid-template-columns: var(--columns);
    grid-gap: 0.6rem;
    margin-top: var(--spacer--xl);

    @media ${b.medium}{
        --columns: 1fr 1fr;
    }
`