import React from 'react'
import styled from 'styled-components'
import b from '../../styles/Breakpoints'
import getFluidImage from '../../util/getFluidImage'
import Img from 'gatsby-image'

export default props => {
    const { image, logo, alt_text: altText } = props

    return (
        <Case>
            <BackgroundImage>
                <Image fluid={getFluidImage(image, {maxWidth: 768})} alt={ altText } />
            </BackgroundImage>
            {logo ? <Logo src={logo?.asset?.url} /> : null}
        </Case>
    )
}

const Case = styled.div`
    position: relative;
    width: 100%;
    border-radius: var(--b--radius);
    overflow: hidden;

    // Hide all other, than the 2 first, on mobile
    &:nth-child(n+3){
        display: none;

        @media ${b.medium}{
            display: block;
        }
    }

    &:before{
        content: "";
        display: block;
        width: 100%;
        padding-top: 70%;
    }
`

const Image = styled(Img)`
    width: 100%;
    height: 100%;
`

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Logo = styled.img`
    position: absolute;
    width: auto;
    height: 100%;
    max-width: 20rem;
    max-height: 5.5rem;
    object-fit: contain;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`